import {db} from './index';
import {collection, doc, getDoc, getDocs, query, setDoc} from 'firebase/firestore';
import {queryRefToObject, snapShotToArray} from './common';
import {getImageDownloadURL} from './storage';

const categoryCollection = collection(db, 'categories');
export const getAllCategories = async () => {
  const array = snapShotToArray(await getDocs(query(categoryCollection)));
  // writeToFile('categories.json', array);
  
  const imageURLs = {};
  
  for (const value of array) {
	imageURLs[value.id] = await getImageDownloadURL(value.image);
  }
  
  return array.map(value => {
    const {updatedAt, createdAt, ...other} = value;
	return {...other, imageURL: imageURLs[value.id]};
  });
};

export const getCategory = async (id) =>
	queryRefToObject(await getDoc(doc(db, 'categories', id)));

export const addNewCategory = async (category) => setDoc(doc(db, 'categories', category.id), category, {merge: true});
