import React, {useEffect, useState} from 'react';
import './Products.scss';
import ProductCard from '../../../components/Product-Card/Product-Card';
import {useParams} from 'react-router-dom';
import {ReactComponent as NoData} from './no_data.svg';
import {useScrollToTop} from '../../../utils/scroll';
import {getProductsForCategory} from '../../../firebase/products.store';
import {getCategory} from '../../../firebase/categories.store';
import {getImageDownloadURL} from '../../../firebase/storage';
import Skeleton from 'react-loading-skeleton';
import {useDispatch, useSelector} from 'react-redux';
import {updateCategoryProducts} from '../../../redux/data.reducer';

const Products = () => {
  const params = useParams();
  const [productCategory, setProductCategory] = useState('');
  const [loading, setLoading] = useState(true);
  const products = useSelector(state => state.data.categoryProducts || {});
  const dispatch = useDispatch();
  
  const productLength = products[productCategory]?.length;
  
  useScrollToTop();
  
  useEffect(() => {
	fetchData();
  }, [params, productLength]);
  
  const fetchData = async () => {
	const {pCategory} = params;
	setProductCategory(await getCategory(pCategory));
	// if (pId) {
	//   const products = [await getProduct(pId)];
	//   for (const product of products) {
	// 	product.imageURL = await getImageDownloadURL(product.image);
	//   }
	//   setProductList(products);
	// } else {
	if (!products[pCategory]) {
	  setLoading(true);
	  const categoryProducts = await getProductsForCategory(pCategory);
	  for (const product of categoryProducts) {
		product.imageURL = await getImageDownloadURL(product.image);
	  }
	  dispatch(updateCategoryProducts({id: pCategory, data: categoryProducts}));
	}
	// }
	setLoading(false);
  };
  
  
  return (
	  <>
		<div className='page'>
		  <h1 className='page-title'>
			{productCategory && !loading ? productCategory.name
				:
				<Skeleton style={{width: '200px', height: '48px'}}/>
			}
		  </h1>
		  <div className='product-list'>
			{
			  loading ?
				  <>
					<ProductCard loading={true}/>
					<ProductCard loading={true}/>
				  </>
				  :
				  products[productCategory.id] && products[productCategory.id].length ?
					  products[productCategory.id].map((value, index) => (
						  <ProductCard product={value} key={`${value.name}-${index}`}/>
					  ))
					  :
					  <>
						<section className='no-data'>
						  <pre>
							
						  </pre>
						  <h1>No Products found!</h1>
						  <NoData style={{height: '480px'}}/>
						</section>
					  </>
			}
		  </div>
		</div>
	  </>
  );
};

export default Products;
