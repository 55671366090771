import './App.scss';
import Navbar from './components/Navbar/Navbar';
import {Link, Outlet} from 'react-router-dom';
import SideList from './components/Side-List/Side-List';
import Footer from './components/Footer/Footer';
import {PhoneIcon} from '@heroicons/react/20/solid';
import Notification from './components/Notification/Notification';

function App() {
  return (<div className='app'>
	<Navbar/>
	<main>
	  <SideList/>
	  <Outlet/>
	</main>
	<Footer/>
	<Link to={'contact/book-service-call'} className='book-service-call'>
	  <div className={'content'}>
		<div className='icon'>
		  <PhoneIcon className={'heroicon-32'}/>
		</div>
		<div className='text'>Book a Service Call!</div>
	  </div>
	</Link>
	<Notification/>
  </div>);
}

export default App;
