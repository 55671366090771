import React from 'react';
import './Home.scss';
import ServicesSection from '../../components/Services-Section/Services-Section';
import Hero from '../../components/Hero/Hero';
import {useScrollToTop} from '../../utils/scroll';
import ProductCategories from '../Product-Categories/ProductCategories';
import Brands from '../../components/Brands/Brands';

const Home = () => {
  
  useScrollToTop();
  
  return (
	  <>
		<section className='home'>
		  <Hero/>
		  <Brands/>
		  <ServicesSection/>
		  <ProductCategories/>
		</section>
	  </>
  );
};

export default Home;
