import React, {useEffect, useRef, useState} from 'react';
import './GetAQuoteProduct.scss';
import {useParams} from 'react-router-dom';
import {getAllProducts} from '../../../firebase/products.store';
import Button from '../../Button/Button';
import {PaperAirplaneIcon} from '@heroicons/react/24/solid';
import {sendMessage} from '../../../firebase/message.store';
import {useDispatch, useSelector} from 'react-redux';
import {setAllProducts} from '../../../redux/data.reducer';
import DropdownSelector from "../../common/DropdownSelector";
import {showNotification} from "../../../redux/notification.reducer";

const GetAQuoteProduct = () => {

    const nameRef = useRef();
    const emailRef = useRef();

    const params = useParams();

    const products = useSelector((state) => state.data.products?.payload || []);
    const dispatch = useDispatch();

    const [productId, setProductId] = useState('');
    const [product, setProduct] = useState(undefined);

    useEffect(() => {
        setProductId(params.pId);
        fetchData();
    }, []);

    useEffect(() => {
        const currentProd = products.find(value => value.id === productId);
        if (currentProd) {
            setProduct({
                value: currentProd.id,
                label: currentProd.name,
                key: `${currentProd.name}-${currentProd.id}`
            });
        }
    }, [productId, products]);

    const fetchData = async () => {
        if (products.length <= 0)
            dispatch(setAllProducts(await getAllProducts()));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const name = nameRef.current.value;
        const email = emailRef.current.value;
        const product = products.find(value => value.id === productId);

        if (name && email && productId) {
            await sendMessage(
                {
                    from: ['Karmit Patel <karmit199@gmail.com>'],
                    to: [`hiren@weightechsolutions.ca`],
                    replyTo: [email],
                    message: {
                        subject: `${name} wants a quote!`,
                        html: `
	  	  From: <h2>${email}</h2>
	  	  <br>
	  	  Name: <h2>${name}</h2>
	  	  <br>
	  	  Product: <h3>${product.name} quote required! - ID: ${productId}<h3>`
                    }
                }
            );
        }

        dispatch(showNotification({
            message: `Thanks for inquiring about ${product.name}! 🚀 We're working on your quote—stay tuned for our response!`,
            title: 'Inquiry Success! 🌟',
            type: 'success',
            duration: 10_000
        }));

        e.target.reset();
    };

    const handleSelect = (option) => {
        setProductId(option.value);
        setProduct(option)
    }

    const getOptions = () => [{
        value: undefined,
        label: 'Select a product to get a quote',
        disabled: true,
        key: 'Select Empty'
    }, ...products.map((product, index) => ({
        value: product.id,
        label: product.name,
        key: `${product.name}-${index}-select`
    }))];

    return (
        <>
            <section className='contact-form'>
                <h1 className={'title'}>Get a Quote</h1>
                <h3 className={'subTitle'}>Choose from a wide variety of our products to get a quotation</h3>
                <form onSubmit={handleSubmit}>
                    <fieldset name={'name'}>
                        <label htmlFor='name'>Name *</label>
                        <input
                            type='text' name='name' id='name' placeholder={'Please enter your name'}
                            autoComplete={'given-name family-name'}
                            required={true}
                            ref={nameRef}
                        />
                    </fieldset>
                    <fieldset name={'email'}>
                        <label htmlFor='email'>Email *</label>
                        <input
                            type='email' name='email' id='email' placeholder={'Please enter your email'}
                            autoComplete={'email'}
                            required={true}
                            ref={emailRef}
                        />
                    </fieldset>
                    <fieldset name={'product'}>
                        <label htmlFor='product'>Product *</label>
                        <DropdownSelector onSelect={handleSelect}
                                          selectedOption={product}
                                          options={getOptions()}
                        />
                    </fieldset>
                    <fieldset/>
                    <div className='action-bar right full'>
                        <Button className={'solid primary pill'} type={'submit'}>
                            Get a Quote
                            <PaperAirplaneIcon className={'heroicon-24'}/>
                        </Button>
                    </div>
                </form>
            </section>
        </>
    );
};

export default GetAQuoteProduct;
