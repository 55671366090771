import React, {useRef} from 'react';
import './Contact-Us.scss';
import Button from '../../Button/Button';
import {PaperAirplaneIcon} from '@heroicons/react/24/solid';
import {sendMessage} from '../../../firebase/message.store';
import {useDispatch} from 'react-redux';
import {showNotification} from '../../../redux/notification.reducer';

const ContactUs = () => {
  
  
  const nameRef = useRef();
  const emailRef = useRef();
  const messageRef = useRef();
  const formRef = useRef();
  
  const dispatch = useDispatch();
  
  const handleSubmit = async (e) => {
	e.preventDefault();
	const name = nameRef.current.value;
	const email = emailRef.current.value;
	const message = messageRef.current.value;
	if (name && email && message) {
	  await sendMessage(
		  {
			from: ['Karmit Patel <karmit199@gmail.com>'],
			to: [`hiren@weightechsolutions.ca`],
			replyTo: [email],
			message: {
			  subject: `${name} is trying to contact you!`,
			  html: `
	  	  From: <h2>${email}</h2>
	  	  <br>
	  	  Name: <h2>${name}</h2>
	  	  <br>
	  	  Message: <h3>${message}</h3>`
			}
		  }
	  );
	  
	  dispatch(showNotification({
		message: 'Thank you for reaching out. Have a fantastic day! 😊',
		title: 'Message Received! We\'re on It!',
		type: 'success',
		duration: 5_000
	  }));
	}
	
	e.target.reset();
  };
  
  return (
	  <>
		<section className='contact-form'>
		  <h1 className={'title'}>Contact Us</h1>
		  <h3 className={'subTitle'}>Reach Out to Us for Any Inquiries or Assistance</h3>
		  <form onSubmit={handleSubmit} ref={formRef}>
			<fieldset name={'name'}>
			  <label htmlFor='name'>Name *</label>
			  <input
				  type='text' name='name' id='name' placeholder={'Please enter your name'}
				  autoComplete={'given-name family-name'}
				  required={true}
				  ref={nameRef}
			  />
			</fieldset>
			<fieldset name={'email'}>
			  <label htmlFor='email'>Email *</label>
			  <input
				  type='email' name='email' id='email' placeholder={'Please enter your email'} autoComplete={'email'}
				  required={true}
				  ref={emailRef}
			  />
			</fieldset>
			<fieldset name={'message'} className={'full'}>
			  <label htmlFor='message'>Message *</label>
			  <textarea
				  name='message' id='message' placeholder={'Enter your message'} required={true} autoComplete={'off'}
				  ref={messageRef}
			  ></textarea>
			</fieldset>
			<div className='action-bar right full'>
			  <Button className={'solid primary pill'} type={'submit'}>
				Send Message
				<PaperAirplaneIcon className={'heroicon-24'}/>
			  </Button>
			</div>
		  </form>
		</section>
	  </>
  );
};

export default ContactUs;
