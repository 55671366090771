import React, {useEffect, useState} from 'react';
import './ProductCategories.scss';
import {Link} from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import {useSelector} from 'react-redux';

const ProductCategories = () => {
  
  const categories = useSelector((state) => state.data.categories?.payload || []);
  const [loading, setLoading] = useState(true);
  
  
  useEffect(() => {
	fetchData();
  }, [categories.length]);
  
  
  const fetchData = async () => {
	setLoading(true);
	if (categories.length > 0) {
	  setLoading(false);
	}
  };
  
  return (
	  <>
		<div className='page'>
		  <div className={'header'}>
			<h1 className='page-title'>Products We Offer</h1>
			<h3 style={{fontWeight: 500}} className={'text'}>
			  At Weigh-Tech Solutions, we are proud to offer a diverse range of services to cater to your needs.
			</h3>
		  </div>
		  <ul className='product-categories'>
			{
			  loading ?
				  <>
					<li className={'category-card'}>
					  <Skeleton className='image'/>
					</li>
					<li className={'category-card'}>
					  <Skeleton className='image'/>
					</li>
					<li className={'category-card'}>
					  <Skeleton className='image'/>
					</li>
					<li className={'category-card'}>
					  <Skeleton className='image'/>
					</li>
				  </>
				  :
				  categories.length > 0 &&
				  categories.map((category, index) => {
					return (
						<Link to={`/products/${category.id}`} key={`${category}-${index}-cards`}>
						  <li className={'category-card'}>
							<img src={category.imageURL} alt='Scale' className={'image'}/>
							<h2>{category.name}</h2>
						  </li>
						</Link>
					);
				  })}
		  </ul>
		</div>
	  </>
  );
};

export default ProductCategories;
