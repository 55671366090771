import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import Home from './pages/Home/Home';
import Services from './pages/Services/Services';
import Contact from './pages/Contact/Contact';
import Products from './pages/Product-Categories/Products/Products';
import ProductCategories from './pages/Product-Categories/ProductCategories';
import {Provider} from 'react-redux';
import store from './redux';

const router = createBrowserRouter([
  {
	path: '/',
	element: <App/>,
	children: [
	  {
		path: '',
		element: <Home/>
	  },
	  {
		path: 'services',
		element: <Services/>
	  },
	  {
		path: 'contact',
		element: <Contact/>
	  },
	  {
		path: 'contact/:id',
		element: <Contact/>
	  },
	  {
		path: 'contact/:id/:pId',
		element: <Contact/>
	  },
	  {
		path: 'products',
		element: <ProductCategories/>
	  },
	  {
		path: 'products/:pCategory',
		element: <Products/>
	  },
	  {
		path: 'products/:pCategory/:pId',
		element: <Products/>
	  }
	]
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
	  <Provider store={store}>
		<RouterProvider router={router}/>
	  </Provider>
	</React.StrictMode>
);
