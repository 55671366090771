import React, {useEffect, useState} from 'react';
import './ServicesSection.scss';
import ServiceCard from './Serivce-Card/Service-Card';
import {getHighlightedServices} from '../../firebase/services.store';

const ServicesSection = () => {
  
  const [highlightedServices, setHighlightedServices] = useState([]);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
	getServices();
  }, []);
  
  const getServices = async () => {
	setLoading(true);
	
	setHighlightedServices(await getHighlightedServices());
	
	setLoading(false);
  };
  return (
	  <>
		<section className='services-section' id={'services-section'}>
		  <section>
			<h2 className='title-small'>Services We Offer</h2>
			<p className='text'>At Weigh-Tech Solutions, we offer a range of services.</p>
		  </section>
		  <br/>
		  <br/>
		  <br/>
		  <ul className='services-cards'>
			{
			  loading ?
				  <>
					<ServiceCard loader={true}/>
					<ServiceCard loader={true}/>
					<ServiceCard loader={true}/>
					<ServiceCard loader={true}/>
				  </>
				  :
				  highlightedServices.length > 0 ? highlightedServices.map((service, index) =>
						  <ServiceCard key={`${service.title}-${index}-card`} service={service}/>
					  )
					  :
					  <></>
			}
		  </ul>
		</section>
	  </>
  );
};

export default ServicesSection;
