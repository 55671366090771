import React, {useEffect, useState} from 'react';
import './SideList.scss';
import {ChevronRightIcon} from '@heroicons/react/20/solid';
import {Link} from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import {useDispatch, useSelector} from 'react-redux';
import {setAllCategories, setAllMetaCategories, setAllMetaServices, setAllServices} from '../../redux/data.reducer';
import {getAllServices} from '../../firebase/services.store';
import {getAllCategories} from '../../firebase/categories.store';
import {getAllMetaCategories} from '../../firebase/meta-categories.store';
import {getAllMetaServices} from '../../firebase/meta-services.store';

const SideList = (props) => {
  
  const services = useSelector((state) => state.data.services?.payload || []);
  const categories = useSelector((state) => state.data.categories?.payload || []);
	const metaServices = useSelector((state) => state.data.metaServices?.payload || []);
	const metaCategories = useSelector((state) => state.data.metaCategories?.payload || []);
  const dispatch = useDispatch();
  
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
	fetchData();
  }, []);
  
  const fetchData = async () => {
	setLoading(true);
	  await fetchMetaServices();
	  await fetchMetaCategories();
	setLoading(false);

	  await fetchServices();
	  await fetchCategories();

  };
  
  const fetchServices = async () => {
	if (!services.length) {
	  dispatch(setAllServices(await getAllServices()));
	}
  };
  
  const fetchCategories = async () => {
	if (!categories.length) {
	  dispatch(setAllCategories(await getAllCategories()));
	}
  };

	const fetchMetaCategories = async () => {
		if (!metaCategories.length) {
			dispatch(setAllMetaCategories(await getAllMetaCategories()));
		}
	};

	const fetchMetaServices = async () => {
		if (!metaServices.length) {
			dispatch(setAllMetaServices(await getAllMetaServices()));
		}
	};

  
  return (
	  <>
		<aside>
		  <ul className='list'>
			<Link to={'services'}>
			  <li className='list-item header'>Services</li>
			</Link>
			{
			  loading ?
				  <>
					<li className='list-item'>
					  <Skeleton style={{width: '80px', height: '20px'}}/>
					  <ChevronRightIcon
						  className={'heroicon-20 color-light-shade-darker'}
					  />
					</li>
					<li className='list-item'>
					  <Skeleton style={{width: '100px', height: '40px'}}/>
					  <ChevronRightIcon
						  className={'heroicon-20 color-light-shade-darker'}
					  />
					</li>
					<li className='list-item'>
					  <Skeleton style={{width: '50px', height: '20px'}}/>
					  <ChevronRightIcon
						  className={'heroicon-20 color-light-shade-darker'}
					  />
					</li>
					<li className='list-item'>
					  <Skeleton style={{width: '120px', height: '20px'}}/>
					  <ChevronRightIcon
						  className={'heroicon-20 color-light-shade-darker'}
					  />
					</li>
					<li className='list-item'>
					  <Skeleton style={{width: '100px', height: '40px'}}/>
					  <ChevronRightIcon
						  className={'heroicon-20 color-light-shade-darker'}
					  />
					</li>
				  </>
				  :
					  metaServices && metaServices.length > 0 ?
							  metaServices.map((service, index) => (
									  <Link to={service.url} key={`${service.title}-${index}`}>
							<li className='list-item'>
								{service.title}
							  <ChevronRightIcon
								  className={'heroicon-20 color-light-shade-darker'}
							  />
							</li>
						  </Link>
					  ))
					  :
					  <></>
			}
		  </ul>
		  <ul className='list'>
			<Link to={'products'}>
			  <li className='list-item header'>Products</li>
			</Link>
			{
			  loading ?
				  <>
					<li className='list-item'>
					  <Skeleton style={{width: '130px', height: '20px'}}/>
					  <ChevronRightIcon
						  className={'heroicon-20 color-light-shade-darker'}
					  />
					</li>
					<li className='list-item'>
					  <Skeleton style={{width: '130px', height: '20px'}}/>
					  <ChevronRightIcon
						  className={'heroicon-20 color-light-shade-darker'}
					  />
					</li>
					<li className='list-item'>
					  <Skeleton style={{width: '100px', height: '20px'}}/>
					  <ChevronRightIcon
						  className={'heroicon-20 color-light-shade-darker'}
					  />
					</li>
					<li className='list-item'>
					  <Skeleton style={{width: '110px', height: '20px'}}/>
					  <ChevronRightIcon
						  className={'heroicon-20 color-light-shade-darker'}
					  />
					</li>
				  </>
				  :
					  metaCategories && metaCategories.length > 0 ?
							  metaCategories.map((value, index) => (
						  <Link to={`products/${value.id}`} key={`${value}-${index}-side`}>
							<li className='list-item'>
								{value.title}
							  <ChevronRightIcon
								  className={'heroicon-20 color-light-shade-darker'}
							  />
							</li>
						  </Link>
					  ))
					  :
					  <></>
			}
		  </ul>
		</aside>
	  </>
  );
};

export default SideList;
