import React from 'react';
import './ServiceSection.scss';
import Button from '../../../components/Button/Button';
import {Link} from 'react-router-dom';
import {ArrowRightCircleIcon} from '@heroicons/react/24/outline';
import Skeleton from 'react-loading-skeleton';

const ServiceSection = ({service, loading, ...otherProps}) => {
  
  if (loading)
	return <section className='service-section'>
	  <h2 className='title-loader'><Skeleton/></h2>
	  <p className={'description-loader'}>
		<Skeleton count={2}/>
	  </p>
	  <p className={'description-loader'}>
		<Skeleton count={3}/>
	  </p>
	  <p className={'description-loader'}>
		<Skeleton count={2}/>
	  </p>
	  <div className='action-bar right'>
		<Skeleton style={{width: '120px', height: '36px'}}/>
	  </div>
	</section>;
  
  
  const {title, longDescription, cta} = service;
  return (
	  <>
		
		<section className='service-section'>
		  <h2 className='title'>{title}</h2>
		  <p dangerouslySetInnerHTML={{__html: longDescription}}></p>
		  {
			  cta &&
			  <>
				<div className='action-bar right'>
				  <Link to={cta.href} target={cta.target}>
					<Button className={'pill outline accent'}>
					  {cta.label}
					  <ArrowRightCircleIcon className={'heroicon-24'}/>
					</Button>
				  </Link>
				</div>
			  </>
		  }
		</section>
	  </>
  );
};

export default ServiceSection;
