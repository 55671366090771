import React, {useEffect, useState} from 'react';
import './Notification.scss';
import {
  CheckBadgeIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XCircleIcon
} from '@heroicons/react/20/solid';
import {useDispatch, useSelector} from 'react-redux';
import {hideNotification} from '../../redux/notification.reducer';

const Notification = () => {
  
  const {show, title, message, type, duration} = useSelector(state => state.notification);
  const [notificationTimeOut, setNotificationTimeOut] = useState(0);
  const dispatch = useDispatch();
  
  useEffect(() => {
	if (show) {
	  if (notificationTimeOut) {
		clearTimeout(notificationTimeOut);
		dispatch(hideNotification());
		setNotificationTimeOut(0);
	  }
	  
	  setNotificationTimeOut(setTimeout(() => {
		dispatch(hideNotification());
		clearTimeout(notificationTimeOut);
		setNotificationTimeOut(0);
	  }, duration));
	}
  }, [show]);
  
  
  const close = () => {
	dispatch(hideNotification());
	clearTimeout(notificationTimeOut);
	setNotificationTimeOut(0);
  };
  
  const getIcon = () => {
	switch (type) {
	  case 'success':
		return <CheckBadgeIcon className={'heroicon-20'}/>;
	  case 'warning':
		return <ExclamationCircleIcon className={'heroicon-20'}/>;
	  case 'danger':
		return <ExclamationTriangleIcon className={'heroicon-20'}/>;
	  default:
		return <InformationCircleIcon className={'heroicon-20'}/>;
	}
  };
  
  
  return (
	  <>
		<div className={`container ${show ? 'show' : ''}`}>
		  <div className={`notification ${type}`}>
			<div className='vertical-line' style={{'--duration': `${duration / 1000}`}}></div>
			<div className='icon'>
			  {
				getIcon()
			  }
			</div>
			<div className='text-content'>
			  <h3 className='title'>{title}</h3>
			  <p className='message'>{message}</p>
			</div>
			<div className='icon close' onClick={close}>
			  <XCircleIcon className={'heroicon-20'}/>
			</div>
		  </div>
		</div>
	  </>
  );
};

export default Notification;
