import React from 'react';
import './Footer.scss';
import CompanyLogo from '../Navbar/Company-Logo.svg';
import MapImage from './Map-Location.png';
import {NavLink} from 'react-router-dom';
import {EnvelopeIcon, MapPinIcon, PhoneIcon} from '@heroicons/react/24/solid';
import {COMPANY_LOCATION, LINKS} from '../../constants/links';

const Footer = () => {
  return (
	  <footer>
		<div className='footer-section company-data'>
		  <img src={CompanyLogo} alt='Company Logo' className='company-logo'/>
		  <a className='line a-tag-link' href={'mailto:hiren@weightechsolutions.ca'}>
			<EnvelopeIcon className={'heroicon-24 color-primary'}/> hiren@weightechsolutions.ca
		  </a>
		  <div className='line'>
			<a href={'tel:+1-437-460-9068'} className={'a-tag-link'}>
			  <PhoneIcon className={'heroicon-24 color-primary'}/>+1-437-460-9068
			</a>
		  </div>
		  <a target={'_blank'} href={COMPANY_LOCATION} rel={'noreferrer'} className={'inline a-tag-link'}>
			<MapPinIcon className={'heroicon-24 color-primary'}/>
			<div>
			  <span className='line'> 598 Edenbrook Hill Drive,</span>
			  <span className='line'> Brampton, Ontario, L7A 4T5</span>
			</div>
		  </a>
		</div>
		<div className='footer-section links-section'>
		  <ul className='links'>
			<li className='link header'>
			  Navigation
			</li>
			{
			  LINKS.map(({to, end, label}, index) =>
				  <NavLink
					  className={({isActive}) => `${isActive ? 'active' : ''} link`} to={to} end={end}
					  key={`${index}-${to}`}
				  >
					{label}
				  </NavLink>
			  )
			}
		  </ul>
		</div>
		<div className='footer-section location'>
		  <h2 className='line header'>
			We are open 24/7
		  </h2>
		  <div className='line'>
			<a target={'_blank'} href={COMPANY_LOCATION} rel={'noreferrer'}>
			  <img src={MapImage} alt='Location' className='map'/>
			</a>
		  </div>
		</div>
	  </footer>
  );
};

export default Footer;
