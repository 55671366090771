import React, {useEffect, useState} from 'react';
import './Contact.scss';
import {useScrollToTop} from '../../utils/scroll';
import {useParams} from 'react-router-dom';
import ContactUs from '../../components/Forms/Contact-Us/Contact-Us';
import BookServiceCall from '../../components/Forms/Book-Service-Call/Book-Service-Call';
import GetAQuoteProduct from '../../components/Forms/Get-A-Quote-Product/Get-A-Quote-Product';

const Contact = () => {
  
  const params = useParams();
  const [id, setId] = useState('');
  
  useEffect(() => {
	const {id} = params;
	setId(id);
  }, [params]);
  
  useScrollToTop();
  
  const getComponent = () => {
	switch (id) {
	  case 'book-service-call':
		return <BookServiceCall/>;
	  case 'get-a-quote':
		return <GetAQuoteProduct/>;
	  default:
		return <ContactUs/>;
	}
  };
  
  return (
	  <>
		<div className='page'>
		  {
			getComponent()
		  }
		</div>
	  </>
  );
};

export default Contact;
