import {createSlice} from '@reduxjs/toolkit';

export const NotificationReducer = createSlice({
  name: 'notification',
  initialState: {
	message: undefined,
	title: undefined,
	show: false,
	type: undefined
  },
  reducers: {
	showNotification: (state, action) => {
	  const {message, title, type, duration} = action.payload;
	  state.title = title;
	  state.message = message;
	  state.type = type;
	  state.duration = duration || 5_000;
	  state.show = true;
	  
	},
	hideNotification: state => {
	  state.show = false;
	}
  }
});

export const {
  showNotification,
  hideNotification
} = NotificationReducer.actions;

export default NotificationReducer.reducer;
