import React, {useEffect, useState} from 'react';
import './Services.scss';
import ServiceSection from './Services-Section/Service-Section';
import GetInTouch from '../../components/Get-In-Touch/Get-In-Touch';
import {useScrollToTop} from '../../utils/scroll';
import {getAllServices} from '../../firebase/services.store';
import {useDispatch, useSelector} from 'react-redux';
import {setAllServices} from '../../redux/data.reducer';

const Services = () => {
  
  const services = useSelector((state) => state.data.services?.payload || []);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  
  useEffect(() => {
	fetchData();
  }, []);
  
  useScrollToTop();
  
  const fetchData = async () => {
	if (!services.length) {
	  setLoading(true);
	  dispatch(setAllServices(await getAllServices()));
	}
	setLoading(false);
  };
  
  return (
	  <>
		<div style={{width: '100%'}}>
		  <section className='services'>
			<h1>
			  Services
			</h1>
			<br/><br/>
			{
			  loading ?
				  <>
					<ServiceSection loading={loading}/>
					<ServiceSection loading={loading}/>
					<ServiceSection loading={loading}/>
					<ServiceSection loading={loading}/>
					<ServiceSection loading={loading}/>
					<ServiceSection loading={loading}/>
				  </>
				  :
				  services.length && services.map((service, index) =>
					  <ServiceSection service={service} key={`${index}-${service.title}`}/>
				  )
			}
		  </section>
		  
		  <GetInTouch/>
		</div>
	  </>
  );
};

export default Services;
