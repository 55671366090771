import React, {useEffect, useState} from 'react';
import './Brands.scss';
import {useDispatch, useSelector} from 'react-redux';
import {setAllCompanies} from '../../redux/data.reducer';
import {getAllCompanies} from '../../firebase/companies.store';
import Skeleton from 'react-loading-skeleton';

const Brands = () => {
  
  const dispatch = useDispatch();
  
  const companies = useSelector(state => state.data.companies || []);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
	fetchData();
  }, []);
  
  const fetchData = async () => {
	if (!companies.length) {
	  setLoading(true);
	  dispatch(setAllCompanies(await getAllCompanies()));
	}
	setLoading(false);
  };
  
  return (
	  <>
		<div className={'company-strip'}>
		  <div>
			<h2 className={'title-small'}>Brands we serve</h2>
			<p className='text'>Discover the extensive range of brands we serve, offering over 40,000 parts and
			  products!</p>
		  </div>
		  {
			loading ?
				<ul className='company-container'>
				  <li>
					<Skeleton style={{width: '200px', height: '150px'}} count={1}></Skeleton>
				  </li>
				  <li>
					<Skeleton style={{width: '200px', height: '150px'}} count={1}></Skeleton>
				  </li>
				  <li>
					<Skeleton style={{width: '200px', height: '150px'}} count={1}></Skeleton>
				  </li>
				  <li>
					<Skeleton style={{width: '200px', height: '150px'}} count={1}></Skeleton>
				  </li>
				  <li>
					<Skeleton style={{width: '200px', height: '150px'}} count={1}></Skeleton>
				  </li>
				  <li>
					<Skeleton style={{width: '200px', height: '150px'}} count={1}></Skeleton>
				  </li>
				  <li>
					<Skeleton style={{width: '200px', height: '150px'}} count={1}></Skeleton>
				  </li>
				  <li>
					<Skeleton style={{width: '200px', height: '150px'}} count={1}></Skeleton>
				  </li>
				  <li>
					<Skeleton style={{width: '200px', height: '150px'}} count={1}></Skeleton>
				  </li>
				  <li>
					<Skeleton style={{width: '200px', height: '150px'}} count={1}></Skeleton>
				  </li>
				  <li>
					<Skeleton style={{width: '200px', height: '150px'}} count={1}></Skeleton>
				  </li>
				  <li>
					<Skeleton style={{width: '200px', height: '150px'}} count={1}></Skeleton>
				  </li>
				</ul>
				:
				<div>
				  {
					companies.length > 0 ?
						<ul className={'carousel company-container'}>
						  {
							companies.filter(value => value.highlight).map((value, index) =>
								<li className='company' key={`${value.id}-${index}`}>
								  <img
									  src={value.imageURL} alt={value.name} className={'company-logo'}
									  aria-label={value.name}
								  />
								</li>
							)
						  }
						</ul>
						: <></>
				  }
				</div>
		  }
		
		</div>
	  </>
  );
};

export default Brands;
