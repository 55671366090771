import React from 'react';
import './Hero.scss';
import Button from '../Button/Button';
import {ArrowRightCircleIcon} from '@heroicons/react/24/outline';
import HeroImage from '../../pages/Home/hero-image.jpg';
import {HERO_CONTENT} from '../../constants/texts';

const Hero = () => {
  const {title, subTitle, cta: {href, label}} = HERO_CONTENT;
  return (
	  <>
		<section className='hero'>
		  <section className='text-content-section'>
			<h1 className='title'>{title}</h1>
			<p className='text' dangerouslySetInnerHTML={{__html: subTitle}}/>
			<br/>
			<a href={href}>
			  <Button className={'solid pill primary'}>
				{label}
				<ArrowRightCircleIcon className={'heroicon-24 color-light'}/>
			  </Button>
			</a>
		  </section>
		  <section className='hero-image-section'>
			<img src={HeroImage} alt='Hero' className={'hero-image'}/>
		  </section>
		</section>
	  </>
  );
};

export default Hero;
