import React from 'react';
import './Button.scss';

const Button = ({className, children, ...otherProps}) => {
  return (
	  <>
		<button className={`btn ${className ?? ''}`} {...otherProps}>
		  {children}
		</button>
	  </>
  );
};

export default Button;
