import {collection, getDocs, orderBy, query} from 'firebase/firestore';
import {db} from './index';
import {snapShotToArray} from './common';
import {getImageDownloadURL} from './storage';

const companyCollection = collection(db, 'companies');
export const getAllCompanies = async () => {
  const array = snapShotToArray(await getDocs(query(companyCollection, orderBy('order', 'asc'))));
  // writeToFile('categories.json', array);
  
  const imageURLs = {};
  
  for (const value of array) {
	imageURLs[value.id] = await getImageDownloadURL(value.image);
  }
  
  return array.map(value => {
	const {updatedAt, createdAt, ...other} = value;
	return {...other, imageURL: imageURLs[value.id]};
  });
};
