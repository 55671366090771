import {db} from './index';
import {collection, doc, getDoc, getDocs, orderBy, query, setDoc, where} from 'firebase/firestore';
import {queryRefToObject, snapShotToArray} from './common';

const productsCollection = collection(db, 'products');

export const getAllProducts = async () => {
  const array = snapShotToArray(await getDocs(query(productsCollection, orderBy('name', 'asc'))));
  // writeToFile('scales.json', array)
  return array.map(value => {
	const {updatedAt, ...other} = value;
	return other;
  });
  
};

export const getProductsForCategory = async (categoryId) => {
  const array = snapShotToArray(await getDocs(query(productsCollection, where('category', '==', categoryId), orderBy('name', 'asc'))));
  return array.map(value => {
    const {updatedAt, createdAt, ...other} = value;
	return other;
  });
};

export const getProduct = async (productId) => queryRefToObject(await getDoc(doc(db, 'products', productId)));

export const addNewProduct = async (scale) => setDoc(doc(db, 'products', scale.id), scale, {merge: true});
