import React from 'react';
import './ProductCard.scss';
import Button from '../Button/Button';
import {DocumentTextIcon} from '@heroicons/react/20/solid';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {LinkIcon} from '@heroicons/react/24/solid';
import {getPDFDownloadURL} from '../../firebase/storage';
import {Link} from 'react-router-dom';

const ProductCard = ({product, loading, ...otherProps}) => {
  
  const downloadFile = async (e) => {
	e.preventDefault();
	const a = document.createElement('a');
	a.href = await getPDFDownloadURL(product.specs.fileName);
	a.download = product.specs.fileName;
	a.target = '_blank';
	a.rel = 'noreferrer';
	a.click();
	a.remove();
  };
  
  return (
	  <>
		{
		  loading ?
			  <div className='product-card loading'>
				<Skeleton className={'thumbnail'}/>
				<div className='text-content'>
				  <h2 className='title'>
					<Skeleton/>
				  </h2>
				  <div className='description'>
					<Skeleton count={10}/>
				  </div>
				  <div className='action-bar right'>
					<Skeleton style={{width: '80px', height: '30px'}}/>
				  </div>
				</div>
			  </div>
			  :
			  <div className='product-card'>
				<img src={product.imageURL} alt='Sample' className='thumbnail'/>
				<div className='text-content'>
				  <h2 className='title'>{product.name}</h2>
				  <div
					  className='description'
					  dangerouslySetInnerHTML={{__html: product.longDescription}}
				  />
				  
				  <div className='action-bar right'>
					{
						product.specs && product.specs.type && <>
						  {
							product.specs.type === 'file' ?
								<Button className={'outline accent'} onClick={downloadFile}>
								  <DocumentTextIcon className={'heroicon-20'}/>
								  Specifications
								</Button>
								:
								<a href={product.specs.href} target={'_blank'} rel={'noreferrer'}>
								  <Button className={'outline accent'}>
									Specifications
									<LinkIcon className={'heroicon-20'}/>
								  </Button>
								</a>
						  }
						</>
					}
					&nbsp;
					<Link to={`/contact/get-a-quote/${product.id}`}>
					  <Button className={'solid primary'}>Get a Quote</Button>
					</Link>
				  </div>
				</div>
			  </div>
		}
	  
	  </>
  );
};

export default ProductCard;
