export const HERO_CONTENT = {
  title: 'Weigh-Tech Solutions',
  subTitle: 'Weigh-Tech Solutions is one stop solutions for all your weighing related requirements. Our wide range of products & services make your weighing operations effortless, economical & high quality.' +
	  '<br>' +
	  '<br>' +
		  'We provide World Class products from RiceLake, A&D, Satorious, Ishida and Avery Weightronix at your door steps.',
  cta: {
	label: `View Our Services`,
	href: '#services-section',
	target: '_self'
  }
};
