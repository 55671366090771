import React, {useState} from 'react';
import './Navbar.scss';
import Button from '../Button/Button';
import {NavLink} from 'react-router-dom';
import {PhoneIcon} from '@heroicons/react/20/solid';
import CompanyLogo from './Final-Logo.png';
import {Bars3BottomRightIcon, EnvelopeIcon, XMarkIcon} from '@heroicons/react/24/solid';
import {LINKS} from '../../constants/links';

const Navbar = (props) => {
  
  const [menuOpen, setMenuOpen] = useState(false);
  
  const toggleMenu = () => {
	setMenuOpen((prevState) => !prevState);
  };
  
  return (
	  <>
		<nav>
		  <div className='navbar'>
			<div className='row'>
			  <div className='logo'>
				<NavLink to={'/'} end style={{cursor: 'pointer'}}>
				  <img src={CompanyLogo} alt='Company Logo' className='company-logo'/>
				</NavLink>
			  </div>
			  <div className='spacer'></div>
			  <div className='mobile-menu-icon'>
				<Button className={'primary icon-only'} onClick={toggleMenu}>
				  {
					menuOpen ?
						<XMarkIcon className={'heroicon-24'}/>
						:
						<Bars3BottomRightIcon className={'heroicon-24'}/>
				  }
				</Button>
			  </div>
			</div>
			<div className='row'>
			  <ul className={`links ${menuOpen ? 'open' : ''}`}>
				{
				  LINKS.map(({to, end, label}, index) =>
					  <NavLink
						  className={({isActive}) => `${isActive ? 'active' : ''} link`} to={to} end={end}
						  key={`${index}-${to}`} onClick={() => {
						setMenuOpen(false);
					  }}
					  >
						{label}
					  
					  </NavLink>
				  )
				}
				
				<span className='spacer'></span>
				<a href={'tel:+1-437-460-9068'} className={'nav-cta laptop'}>
				  <Button className={'primary outline small'}>
					<PhoneIcon className={'heroicon-16'}/>
					<span className='phone-text'>+1.437.460.9068</span>
				  </Button>
				</a>
				<a
					href='mailto:hiren@weightechsolutions.ca' target={'_blank'} className={'nav-cta laptop'}
					rel={'noreferrer'}
				>
				  <Button className={'primary solid small'}>
					<EnvelopeIcon className={'heroicon-16'}/>
					<span className='email-text'>hiren@weightechsolutions.ca</span>
				  </Button>
				</a>
				
				<a href={'tel:+1-437-460-9068'} className={'nav-cta mobile'}>
				  <Button className={'primary outline'}>
					<PhoneIcon className={'heroicon-20'}/>
					<span className='phone-text'>+1.437.460.9068</span>
				  </Button>
				</a>
				<a
					href='mailto:hiren@weightechsolutions.ca' target={'_blank'} className={'nav-cta mobile'}
					rel={'noreferrer'}
				>
				  <Button className={'primary solid'}>
					<EnvelopeIcon className={'heroicon-20'}/>
					<span className='email-text'>hiren@weightechsolutions.ca</span>
				  </Button>
				</a>
			  </ul>
			</div>
		  </div>
		</nav>
	  </>
  );
};

export default Navbar;
