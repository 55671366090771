import React, {useEffect, useRef, useState} from 'react';
import './DropdownSelector.scss';
import {ChevronDownIcon} from "@heroicons/react/20/solid";

const DropdownSelector = React.forwardRef(({
                                               selectedOption: propSelectedOption,
                                               onSelect,
                                               placeholder,
                                               options
                                           }, ref) => {
    const [selectedOption, setSelectedOption] = useState(propSelectedOption || null);
    const [isOpen, setIsOpen] = useState(false);
    const [isBottom, setIsBottom] = useState(false);
    // Forward the ref to the outer div element
    const dropdownRef = useRef(null);

    const handleToggle = () => {
        // Check if the component is at the bottom of the viewport
        const rect = dropdownRef.current.getBoundingClientRect();

        setIsBottom(rect.bottom + 200 + 16 >= window.innerHeight);

        setIsOpen(!isOpen);
    };

    const handleOptionClick = (option) => {
        if (!option.disabled) {
            setSelectedOption(option);
            setIsOpen(false);
            if (onSelect) {
                onSelect(option);
            }
        }
    };

    const handleKeyDown = (e) => {
        const key = e.key.toLowerCase();

        if (e.key === 'Enter') {
            handleToggle()
        } else if (e.key === 'Escape') {
            setIsOpen(false);
        } else if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
            e.preventDefault();
            const index = options.findIndex((value) => value.value === selectedOption.value);
            const nextIndex =
                e.key === 'ArrowDown' ? (index + 1) % options.length : (index - 1 + options.length) % options.length;
            setSelectedOption(options[nextIndex]);
        } else if (/^[a-zA-Z]$/.test(key)) {
            // Handle letter keypress for filtering options
            const matchingOption = options.find(option => !option.disabled && option.label.toLowerCase().startsWith(key));
            if (matchingOption) {
                setSelectedOption(matchingOption.label);
            }
        }
    };

    useEffect(() => {
        if (isOpen && selectedOption?.value) {
            const selectedOptionElement = dropdownRef.current.querySelector(`li[value="${selectedOption.value}"]`);
            if (selectedOptionElement) {
                selectedOptionElement.scrollIntoView({
                    block: 'nearest',
                    inline: 'start',
                });
            }
        }

        const handleOutsideClick = (e) => {
            if (e.target.closest('.dropdown-selector') === null) {
                setIsOpen(false);
            }
        };

        window.addEventListener('click', handleOutsideClick);

        if (propSelectedOption && !selectedOption) {
            setSelectedOption(propSelectedOption)
        }

        return () => {
            window.removeEventListener('click', handleOutsideClick);
        };
    }, [isOpen, selectedOption, propSelectedOption]);

    React.useImperativeHandle(ref, () => ({
        focus: () => {
            dropdownRef.current.focus();
        },
    }));

    return (
        <div className={`dropdown-selector ${isBottom && 'open-up'}`} tabIndex="0" onKeyDown={handleKeyDown}
             ref={dropdownRef}>
            <div className="selected-option" onClick={handleToggle}>
                {(selectedOption && selectedOption.label) || placeholder || 'Select an option'}
                <div className="spacer"></div>
                <ChevronDownIcon className={`heroicon-20 chevron-icon ${isOpen && 'open'}`}/>
            </div>
            {isOpen && (
                <ul className="options">
                    {options.map((option) => (
                        <li
                            key={option.key}
                            onClick={() => handleOptionClick(option)}
                            className={selectedOption && option.value === selectedOption.value ? 'selected' : option.disabled ? 'disabled' : ''}
                            value={option.value}
                        >
                            {option.label}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
});

export default DropdownSelector;
