import React from 'react';
import './GetInTouch.scss';
import Button from '../Button/Button';
import {Link} from 'react-router-dom';
import {ArrowRightCircleIcon} from '@heroicons/react/24/outline';

const GetInTouch = () => {
  return (
	  <>
		<section className='get-in-touch'>
		  <Link to={'/contact'}>
			<Button className={'light solid'}>
			  <h3>Get in touch with us!</h3>
			  <ArrowRightCircleIcon className={'heroicon-24'}/>
			</Button>
		  </Link>
		</section>
	  </>
  );
};

export default GetInTouch;
