import {createSlice} from '@reduxjs/toolkit';

export const DataReducer = createSlice({
  name: 'data',
  initialState: {
	products: [],
	categories: [],
	services: [],
	companies: [],
	  metaServices: [],
	  metaCategories: [],
	categoryProducts: {}
  },
  reducers: {
	setAllProducts: (state, payload) => {
	  state.products = payload;
	},
	setAllCategories: (state, payload) => {
	  state.categories = payload;
	},
	setAllServices: (state, payload) => {
	  state.services = payload;
	},
	setAllCompanies: (state, payload) => {
	  state.companies = payload.payload;
	},
	  setAllMetaCategories: (state, payload) => {
		  state.metaCategories = payload;
	  },
	  setAllMetaServices: (state, payload) => {
		  state.metaServices = payload;
	  },
	updateCategoryProducts: (state, payload) => {
	  state.categoryProducts = {...state.categoryProducts, [payload.payload.id]: payload.payload.data};
	}
  }
});

export const {
  setAllProducts,
  setAllCategories,
  setAllServices,
  setAllCompanies,
	setAllMetaCategories,
	setAllMetaServices,
  updateCategoryProducts
} = DataReducer.actions;

export default DataReducer.reducer;
