import {db} from './index';
import {collection, doc, getDocs, orderBy, query, setDoc, where} from 'firebase/firestore';
import {snapShotToArray} from './common';


const serviceCollection = collection(db, 'services');
export const getHighlightedServices = async () => snapShotToArray(await getDocs(query(serviceCollection, where('highlight', '==', true), orderBy('order', 'asc'))));
export const getAllServices = async () => {
  const array = snapShotToArray(await getDocs(query(serviceCollection, orderBy('order', 'asc'))));
  // writeToFile('service.json', array)
  return array.map(value => {
    const {updatedAt, createdAt, ...other} = value;
    return other;
  });
};

export const addNewService = async (service) => setDoc(doc(db, 'services', service.id), service, {merge: true});
